<template>
  <DisplayLoading
    :isLoading="isLoading"
    :isError="isError"
    :loadingMessage="loadingMessage"
    :errorMessage="errorMessage"
  >
    <div v-if="pageData">
      <PageHeader text="Проекты">
        <AddButton buttonText="Добавить проект" />
        <DownloadAllProjectsResults />
      </PageHeader>
      <TableWithSearch
        :headers="headers"
        :items="projects"
        :rowClickLink="rowClickLink"
      >
      </TableWithSearch>
    </div>
  </DisplayLoading>
</template>

<script>
import DisplayLoading from '@/components/common/DisplayLoading.vue'
import AddButton from '@/components/buttons/AddButton.vue'
import PageHeader from '@/components/common/PageHeader.vue'
import TableWithSearch from '@/components/common/TableWithSearch.vue'
import DownloadAllProjectsResults from '@/components/buttons/DownloadAllProjectsResults.vue'

import { loadPageData } from '@/mixins/loadPageData'
import { calculatePercent } from '@/lib/percent.js'

import { API_PROJECTS } from '@/store/const/apiPaths'
import { ADMIN, PROJECTS, PROJECT_SHORT } from '@/store/const/path'
import { PROJECT_STAGES } from '@/store/const/projectStages'

const CALC_NOT_AVAILABLE = -2
const CALC_ERROR = -1

export default {
  data() {
    return {
      loadUrl: API_PROJECTS,
      errorMessage: 'Не удалось загрузить проекты',
      loadingMessage: 'Загружаю проекты',
      headers: [
        { text: 'Проект', value: 'name', width: 800 },
        { text: 'Статус', value: 'stageName' },
        { text: 'Готовность этапа', value: 'percentReady', align: 'end' },
      ],
    }
  },
  mixins: [loadPageData],
  components: {
    AddButton,
    PageHeader,
    DisplayLoading,
    TableWithSearch,
    DownloadAllProjectsResults,
  },
  computed: {
    projects() {
      return this.pageData.map(p => ({
        ...p,
        stageName: PROJECT_STAGES[p.stage_id].name,
        ...this.getPercentReady(p),
      }))
    },
    rowClickLink() {
      return `/${ADMIN}/${PROJECTS}/${PROJECT_SHORT}`
    },
  },
  methods: {
    getPercentReady(project) {
      if (!PROJECT_STAGES[project.stage_id].external)
        return {
          percentReady: CALC_NOT_AVAILABLE,
          percentReadyReadable: '—',
        }

      const result = calculatePercent(
        project.completed_staged_count,
        project.common_staged_count
      )
      return {
        percentReady: isNaN(result) ? CALC_ERROR : result,
        percentReadyReadable: isNaN(result) ? 'Ошибка расчета' : `${result}%`,
      }
    },
  },
}
</script>
