<template>
  <v-btn small text @click="onExcelDownload" :loading="loading">
    <v-icon class="mr-1">mdi-download</v-icon>
    <span>Отчет по всем проектам</span>

    <template v-slot:loader>
      <div style="width: 90%">
        <span>Готовим отчет</span>
        <v-progress-linear :value="progress" />
      </div>
    </template>
  </v-btn>
</template>

<script>
import { routeParams } from '@/mixins/routeParams'
import Excel from '@/lib/excel.js'
import { API_PROJECTS_REPORT } from '@/store/const/apiPaths'

const FULL_PROGRESS = 100
const EXPECT_TIME_MS = 15000

export default {
  mixins: [routeParams],
  data() {
    return {
      interval: null,
      loading: false,
      progress: 0,
    }
  },
  methods: {
    async onExcelDownload() {
      try {
        this.setLoading(true)
        const excel = new Excel({
          link: `${API_PROJECTS_REPORT}`,
          fileName: `Отчет по всем проектам.xlsx`,
          creatorName: this.$store.getters.getLoggedUser.fio,
        })
        await excel.downloadProject()
      } finally {
        this.setLoading(false)
      }
    },
    increaseProgress() {
      this.progress = this.progress === FULL_PROGRESS ? 0 : this.progress + 1
    },
    setLoading(set) {
      this.progress = 0
      this.loading = set
      if (set) {
        this.interval = setInterval(
          this.increaseProgress,
          EXPECT_TIME_MS / FULL_PROGRESS
        )
      } else {
        this.progress = FULL_PROGRESS
        clearInterval(this.interval)
      }
    },
  },
}
</script>
